@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

* {
  font-family: 'Poppins' !important;
  box-sizing: border-box;
}


.header {
  /* padding-top: 25px;
  padding-bottom: 25px; */
  border-radius: 10px;
  line-height: 0.5;

}

.header-content {
  position: relative;
  align-items: center;
  font-size: medium;
  height: 50px;
  border-radius: 10px;
  background: rgb(190, 219, 255);
  background: linear-gradient(180deg, #418AD2 0%, #0725F0 100%);
  box-shadow: 4px 4px 25px rgba(56, 93, 255, 0.3);
}

.header-font-style {
  position: relative;
  font-size: medium;
  margin-left: 3%;
  padding-top: 14px;
  font-weight: bold;
  color: white;
}


body {
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
  height: 100vh;
  margin: -20px 0 50px;
}

.error {
  border: 1px solid rgb(240, 20, 20) !important;
  border-radius: 8px;
}

.error-message {
  font-weight: lighter;
  font-family: sans-serif !important;
  color: red;
}

.first-column {

  align-items: center;
  background-color: white;
  border-right: 2px solid #e4e2e2;
  border-top: 2%;

}

.second-column {
  display: flex;
  flex-direction: column;
  border-width: 2px;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.container {
  background-color: white;
  border-radius: 10px;
  position: relative;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 200%;
  height: 100%;
  min-height: 500px;
  column-width: 900px;
  margin-top: 0%;
  max-width: 1117px;
  margin-bottom: 5%;
}

.form {
  padding-top: 2%;
  padding: 0 20px !important;
}

.input-control {
  margin-left: 6%;
  margin-right: 5%;
  margin-top: 5%;
}


.output-header {
  margin-left: 10%;
  margin-right: 5%;
  margin-top: 5%;
  font-family: Helvetica;
}

.icon-input {
  display: flex;
  align-items: center;
  padding: .375rem .40rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #E0E3EB;
  border: 1px solid #ced4da;
  border-radius: .375rem;

}

.icon-prefix {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.icon-postfix {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.get-quote,
.get-quote-disabled {
  margin-top: 20px;
  font-size: 0.8em !important;
  font-weight: bolder !important;
  line-height: 2.5 !important;
  margin-left: -5px !important;
  font-size: x-small;
}

.get-quote-disabled {
  border-color: #81848C !important;
  color: rgb(192, 193, 194) !important;
}

.get-quote:hover {
  background: rgb(216, 242, 255);
  background: linear-gradient(179deg, rgba(29, 46, 231, 1) 5%, rgba(71, 84, 239, 1) 100%);
}

.get-quote::after {
  background-color: rgba(29, 46, 231, 1);
}

.quotepPressed {
  background-color: rgba(29, 46, 231, 1) !important;
  color: rgb(192, 193, 194) !important;
}

.button-65 {
  appearance: none;
  backface-visibility: hidden;
  background: rgb(216, 242, 255);
  background: linear-gradient(180deg, #4958F6 0%, #021DF4 100%);
  border-radius: 7px;
  border-style: inset;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  height: 50px;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 14px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.output-group {
  margin-top: 30px;
  display: flex;
  justify-content: left;
}

.result-header {
  margin-top: 30px;
  justify-content: center;
  color: #375cf5;
}

.result-descr {
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.vert-row {
  margin-left: 2%;
  border-top: 2px solid #E0E3EB;
  width: 400px;
}

.result-label {
  justify-content: left;
  font-weight: bold;
  margin-top: 30px;

}

.result-labe {
  display: flex;
  justify-content: right !important;
  margin-top: 30px;
  font-weight: bold;
  color: #375cf5;
}

.before-calculation {
  display: inline-block;
  opacity: 0.5;
  width: 80%;
  justify-content: space-evenly;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #3D3D3D;
}

.after-calculation {
  width: 85% !important;
  margin-left: 10% !important;
}

.sponsor_link {
  padding-right: 25px !important;
  display: flex;
  justify-content: right;
  margin-top: 6%;
}

a {
  text-decoration: none !important;
  font-family: 'Poppins' !important;
  color: #283555 !important;
  font-weight: 400 !important;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0em;
  opacity: 0.5;
}

@media (min-width: 768px) {
  .button-65 {
    padding: 14px 22px;
    width: 176px;
  }
}